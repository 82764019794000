import he from 'he'

export const truncateString = (string, characterCount) =>
  string.length > characterCount ? string.slice(0, characterCount) + '...' : string

export const truncateHtmlString = (string, characterCount) =>
  truncateString(decodeHtmlString(string.replace(/<[^>]+>/g, '')), characterCount)

export const decodeHtmlString = (string) => he.decode(string)

export const storyLink = (story) => {
  const POST_TYPES = Object.freeze({
    standard_post: 'blogs',
    barstool_original: 'videos',
    podcast: 'podcasts'
  })
  return {
    href: `/${POST_TYPES[story.type]}/[id]/[slug]`,
    as: `/${POST_TYPES[story.type]}/${story.id}/${story.slug}`
  }
}

export const pluralizeIfNeeded = (singularString, count) => {
  return Number(count) === 1 ? singularString : `${singularString}s`
}
