import {
  isFuture,
  isPast,
  formatDistanceToNow,
  formatDistanceStrict,
  format,
  differenceInDays,
  isDate,
  parseISO,
  addHours,
  getHours,
  getMinutes,
  getSeconds
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const isLive = (date) => {
  return isPast(new Date(date))
}

export const startsIn = (date) => {
  const upcoming = isFuture(new Date(date))
  return upcoming ? formatDistanceToNow(new Date(date)) : 'Live'
}

export const formatDate = (date, formatString = 'M/d h:mm a') => {
  let parsedDate
  if (isDate(date)) parsedDate = date
  else parsedDate = parseISO(date)

  return format(parsedDate, formatString)
}

export const wordsOrDate = (date) => {
  const isOld = differenceInDays(new Date(), new Date(date)) > 5
  return isOld ? formatDate(date, 'M/d/yy h:mm a') : formatDistanceStrict(new Date(), new Date(date)) + ' ago'
}

export const convertToDuration = (milliseconds) => {
  if (!milliseconds) return null
  const normalizeTime = (time) => (time.length === 1 ? `0${time}` : time)

  const date = new Date(milliseconds)
  const timezoneDiff = date.getTimezoneOffset() / 60
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

  const hoursOutput = hours !== '00' ? `${hours}:` : ''

  return `${hoursOutput}${minutes}:${seconds}`
}

export const hoursStringToTime = (time, zone) => {
  let timeString = (time + ':00')
    .split('')
    .map((char, idx) => {
      if (idx === 2) {
        return ':' + char
      } else {
        return char
      }
    })
    .join('')
  const zonedTime = utcToZonedTime('1970-01-01T' + timeString, zone)
  return format(new Date(zonedTime), 'h:mm aa')
}
