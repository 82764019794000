import { useStaticRendering } from 'mobx-react'
import { isServer } from './server'

useStaticRendering(isServer) // eslint-disable-line react-hooks/rules-of-hooks

const _storeCache = {}

export const getStore = (Store) => {
  if (!Store.cacheKey) {
    throw new Error(`Store (${Store.name}) is missing a cacheKey`)
  }
  const store = _storeCache[Store.cacheKey] || Store()
  _storeCache[Store.cacheKey] = store
  return store
}

export const resetStoreCache = () => {
  for (const key of Object.keys(_storeCache)) {
    _storeCache[key] = undefined
  }
}
