import { observable } from 'mobx'
import { reviewsApi } from 'api'
import { CommonStore } from 'stores/common'

export const SidebarStore = () => {
  return observable({
    ...CommonStore,
    api: reviewsApi,
    itemsDave: []
  })
}

SidebarStore.cacheKey = 'SidebarStore'

export default SidebarStore
