import loadScript from 'load-script'

const loadScriptAsync = function (src) {
  const promiseFunction = function (url) {
    return function (resolve, reject) {
      loadScript(url, {}, function (error, script) {
        error ? reject(error) : resolve(script)
      })
    }
  }
  return new Promise(promiseFunction(src))
}

class ExternalScriptLoader {
  loaded = false
  loadingPromise = null

  async loadScript() {
    if (!this.scriptUrl) throw new Error('Child class must implement scriptUrl')
    if (this.loaded) return Promise.resolve()
    if (this.loadingPromise) return this.loadingPromise
    try {
      this.loadingPromise = loadScriptAsync(this.scriptUrl)
      await this.loadingPromise
      this.loaded = true
    } catch (e) {
      console.error(`Error loading script (${this.scriptUrl}): ${e.message}`)
    } finally {
      this.loadingPromise = null
    }
  }
}

export default ExternalScriptLoader
