import TagManager from 'react-gtm-module'
import replace from 'lodash/replace'
import reduce from 'lodash/reduce'

export const sendEvent = (event) => {
  TagManager.dataLayer({ dataLayer: { event } })
}

export const pageview = () => {
  sendEvent('virtual_pageview')
}

export const setupVideoTracking = (player) => {
  let reviewWatched = false
  const play = () => sendEvent('video_play')
  const pause = () => sendEvent('video_pause')
  const end = () => sendEvent('video_end')
  const adStarted = () => sendEvent('video_ad_started')
  const timeUpdate = () => {
    const currentTime = Math.round(player.currentTime())
    const duration = Math.round(player.duration())
    const percentPlayed = Math.round((currentTime / duration) * 100)
    if (percentPlayed >= 34 && !reviewWatched) {
      reviewWatched = true
      sendEvent('review_watched')
    }
  }

  player.on('timeupdate', timeUpdate)
  player.on('play', play)
  player.on('pause', pause)
  player.on('ended', end)
  player.on('ads-ad-started', adStarted)
}

export const formatOrderUrl = (url) => {
  const UTM_MAPPING = {
    utm_source: 'barstoolsports',
    utm_medium: 'web',
    utm_content: 'venue_detail',
    utm_campaign: 'barstoolsports_web_venue_detail',
    user_id: null
  }

  return reduce(
    UTM_MAPPING,
    (result, value, key) => {
      return replace(result, `{${key}}`, value)
    },
    url
  )
}
