import { OneBiteResource } from 'api/resource'

class AuthApi extends OneBiteResource {
  constructor() {
    super('auth')
  }
  async resetPassword(data) {
    const response = await this.post({ url: '/auth/reset-password', data })
    return response
  }
}

export default AuthApi
