import { observable, flow } from 'mobx'

const NAVIGATION_ITEMS = [
  { title: 'Dave’s Reviews', href: '/reviews/dave', primary: true },
  { title: 'Restaurants', href: '/restaurant', primary: true },
  { title: 'Celebrities', href: '/reviews/celebrity', primary: true },
  { title: 'Fan Reviews', href: '/reviews/fans', primary: true },
  { title: 'Search', href: '/search', primary: true }
]

export const NavigationStore = () => {
  const open = flow(function* (item) {
    this.currentDropdown = item
    document.querySelector('.layout__overlay').classList.add('layout__overlay--active')
    yield
  })
  const close = flow(function* () {
    this.currentDropdown = null
    document.querySelector('.layout__overlay').classList.remove('layout__overlay--active')
    yield
  })

  return observable({
    navigationItems: NAVIGATION_ITEMS,
    open,
    close,
    currentDropdown: null
  })
}

NavigationStore.cacheKey = 'NavigationStore'

export default NavigationStore
