import { observable, flow } from 'mobx'
import { reviewsApi } from 'api'
import { CommonStore } from 'stores/common'

export const ReviewsStore = () => {
  const findReviewsByUser = flow(function* (userId, params = {}) {
    const response = yield this.api.get({ url: `/user/${userId}/review`, params })
    this.items = response
    return response
  })

  const findDaveReviews = flow(function* (params = {}) {
    const response = yield this.api.get({ url: '/user/~dave~/review', params })
    this.itemsDave = response
    return response
  })

  const findByVenue = flow(function* (venueId, params = {}) {
    const response = yield this.api.get({ url: `/venue/${venueId}/review`, params })
    this.items = response
    return response
  })

  return observable({
    ...CommonStore,
    api: reviewsApi,
    findReviewsByUser,
    findDaveReviews,
    findByVenue,
    daveCurrent: null,
    itemsDave: []
  })
}

ReviewsStore.cacheKey = 'ReviewsStore'

export default ReviewsStore
