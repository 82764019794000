import AuthApi from 'api/auth'
import CitiesApi from 'api/cities'
import ReviewsApi from 'api/reviews'
import StoreApi from 'api/store'
import VenuesApi from 'api/venues'

const authApi = new AuthApi()
const citiesApi = new CitiesApi()
const reviewsApi = new ReviewsApi()
const storeApi = new StoreApi()
const venuesApi = new VenuesApi()

export { authApi, citiesApi, reviewsApi, storeApi, venuesApi }
