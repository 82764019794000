import React from 'react'
import TagManager from 'react-gtm-module'
import App from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import quantcast from 'lib/quantcast'
import { isServer, getStore, resetStoreCache, pageview } from 'utils'

import { ReviewsStore } from 'stores/reviews'
import { SidebarStore } from 'stores/sidebar'
import { NavigationStore } from 'stores/navigation'

import { useSyncSegment } from '@barstoolsports/web-sdk'

class OneBiteApp extends App {
  static async getInitialProps({ Component, ctx }) {
    if (isServer) {
      resetStoreCache()
    }
    const [pageProps, reviews] = await Promise.all([
      Component.getInitialProps ? Component.getInitialProps(ctx) : {},
      ReviewsStore().find({ userType: 'DAVE', limit: 3 })
    ])

    return { pageProps, reviews }
  }

  async componentDidMount() {
    this._monitorRouteChanges()

    quantcast.loadScript()

    TagManager.initialize({ gtmId: 'GTM-WQWL5TR' })
    const sidebarStore = getStore(SidebarStore)
    sidebarStore.hydrate({ itemsDave: this.props.reviews })
  }

  render() {
    const { Component, pageProps } = this.props
    return <Component {...pageProps} />
  }

  _monitorRouteChanges() {
    const navigationStore = getStore(NavigationStore)
    const cachedPageHeight = []

    let nextScrollPosition = 0

    window.history.scrollRestoration = 'manual'

    Router.events.on('routeChangeStart', () => {
      cachedPageHeight.push(window.scrollY)

      NProgress.start()
      navigationStore.close()
      pageview()
    })

    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, nextScrollPosition)
      nextScrollPosition = 0
      NProgress.done()

      // Raygun reporting, page change tracking
      window.rg4js?.('trackEvent', { type: 'pageview', path: document.location.pathname })
    })

    Router.events.on('routeChangeError', () => {
      NProgress.done()
    })

    Router.beforePopState(() => {
      nextScrollPosition = cachedPageHeight.pop() || 0
      return true
    })
  }
}

function segmentHookWrapper(Component) {
  return function WrappedComponent(props) {
    useSyncSegment(process.env.SEGMENT_KEY ?? '')
    return <Component {...props} />
  }
}

export default segmentHookWrapper(OneBiteApp)
