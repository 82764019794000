export const THEME = {
  COLORS: {
    PRIMARY: '#C62232',
    SECONDARY: '#F9E5AD',
    YELLOW: '#F0C030',
    GRAY: '#757575',
    LIGHTGRAY: '#D8D8D8',
    LIGHTESTGRAY: '#F9F9F9'
  },
  BREAKPOINTS: {
    XS: '576px',
    SM: '768px',
    MD: '960px',
    LG: '1024px',
    XL: '1200px'
  }
}
